<template>
  <div class="">
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{ $trans('Players') }}
      </span>
    </h4>
    <div class="card">
      <div class="card-body">
        <div v-if="transactions && transactions.data" class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ transactions.data.length }}</strong> {{ $trans('of') }}
              <strong>{{ transactions.total }}</strong></small>
          </div>
          <table class="table table-striped mb-3">
            <thead>
            <tr>
              <th>{{ $trans('Phone') }}</th>
              <th>{{ $trans('Name') }}</th>
              <th>{{ $trans('Referer') }}</th>
              <th>{{ $trans('Date') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(transaction,index) in transactions.data" v-bind:key="index">
              <td>{{transaction.phone}}</td>
              <td>{{transaction.name}}</td>
              <td>{{transaction.referer}}</td>
              <td>{{transaction.created_at}}</td>
            </tr>
            </tbody>
          </table>
          <paginate v-if="transactions && transactions.data && transactions.last_page"
                    :page-count="transactions.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Players",
  data: function () {
    return {
      addForm:false,
      alert:null,
      errors:null,
      process_loading:false,
      transactions:null,
      filterQuery:{
        page:1
      }
    }
  },
  methods: {
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/players', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.transactions = resp.data;
            this.process_loading = false
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
    this.index()
  },
  computed: {
  }
}
</script>


<style scoped>

</style>